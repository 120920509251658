import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { type ServerError } from '../models/serverError';

declare let toastr: any;

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  private readonly translate = inject(TranslateService);

  public success(message: string): void {
    toastr.success(this.translate.instant(message));
  }

  public info(message: string): void {
    toastr.info(this.translate.instant(message));
  }

  public error(message: string, options: Record<string, string> = {}): void {
    toastr.error(this.translate.instant(message, options));
  }

  public warning(message: string): void {
    toastr.warning(this.translate.instant(message));
  }

  public displayServerErrors(errorResponse: any, customErrors?: Record<string, string>): void {
    if (errorResponse?.error?.errors) {
      errorResponse.error.errors.forEach((err) => {
        if (customErrors && customErrors[err.errorCode]) {
          this.translate
            .get(customErrors[err.errorCode])
            .toPromise()
            .then((translation) => {
              const msg = translation !== customErrors[err.errorCode] ? translation : err.errorMessage;
              if (msg) {
                toastr.error(msg);
              }
            });
        } else {
          this.serverError(err);
        }
      });
      return;
    }

    if (errorResponse?.error?.errorMessage) {
      toastr.error(errorResponse?.error?.errorMessage);
      return;
    }

    this.error('common.errorMessages.unexpected');
  }

  public serverError(error: ServerError): void {
    const errorPath = `common.serverErrors.${error.errorCode}`;

    const translation = this.translate.instant(errorPath);
    const message = translation !== errorPath ? translation : error.errorMessage;

    if (message) {
      toastr.error(message);
    }
  }
}
