import { Directive, ElementRef, HostListener, inject, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appFocusable]',
  standalone: true,
})
export class FocusableDirective implements OnChanges {
  private readonly ref = inject(ElementRef);
  @Input('appFocusable')
  enabled = true;

  private readonly renderer = inject(Renderer2);

  constructor() {
    this.setFocusable(this.enabled);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFocusable(changes['enabled'].currentValue);
  }

  @HostListener('keydown.enter') onKeydownEnter(): void {
    if (!this.enabled) return;

    this.ref.nativeElement.click();
  }

  private setFocusable(enabled: boolean): void {
    if (enabled) {
      const el = this.ref.nativeElement as HTMLElement;
      this.renderer.setAttribute(el, 'tabindex', '0');
      this.renderer.addClass(el, 'focusable');
    } else {
      const el = this.ref.nativeElement as HTMLElement;
      this.renderer.removeAttribute(el, 'tabindex');
      this.renderer.removeClass(el, 'focusable');
    }
  }
}
