import { Directive, ElementRef, Input, type OnChanges, Renderer2, type SimpleChanges, inject } from '@angular/core';

@Directive({
  selector: '[appBtnLoading]',
  standalone: true,
})
export class ButtonLoadingDirective implements OnChanges {
  @Input('appBtnLoading')
  isLoading: boolean;

  private savedContent: string;
  private savedWidth: string;
  private timeout: ReturnType<typeof setTimeout>;

  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['isLoading']) return;

    const isLoading = changes['isLoading'].currentValue;
    const elementRef = this.elementRef.nativeElement as HTMLElement;

    if (isLoading) {
      this.setDisabled(true);

      this.timeout = setTimeout(() => {
        this.savedContent = elementRef.innerHTML;
        this.savedWidth = elementRef.style.width;

        this.renderer.setStyle(elementRef, 'width', `${elementRef.clientWidth}px`);
        elementRef.innerHTML = `<div class="btn-loader"></div>`;
      }, 500);

      return;
    }

    if (!this.timeout) return;
    clearTimeout(this.timeout);

    if (this.savedContent) elementRef.innerHTML = this.savedContent;
    if (this.savedWidth) this.renderer.setStyle(elementRef, 'width', this.savedWidth);

    this.setDisabled(false);
  }

  private setDisabled(value: boolean): void {
    switch (value) {
      case true:
        this.renderer.addClass(this.elementRef.nativeElement, 'disabled');
        break;
      case false:
        this.renderer.removeClass(this.elementRef.nativeElement, 'disabled');
        break;
    }
  }
}
